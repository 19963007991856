import React from 'react';
import * as NS from './Navbar.styles';
import twittersvg from '../../images/twitter.svg';
import instagram from '../../images/instagram.svg';
const Navbar = () => {

    return (
        <NS.Nav id={"nav"} className={"navbar navbar-expand-md navbar-light sticky-top"}>
        <div className='container'>
            <NS.NavLogo>
                AI ill
            </NS.NavLogo>
            <NS.MenuIcon>
                <a href={'https://twitter.com/aiillustrator'}>
                    <img src={twittersvg} width={'30'} alt="Follow on Twitter" />
                </a>
                <a href={'https://instagram.com/theaiillustrator'}>
                    <img src={instagram} width={'30'} alt="Follow on Instagam" />
                </a>
            </NS.MenuIcon>
        </div>
        </NS.Nav>
    )
}

export default Navbar;
