import * as HS from './Hero.styles';
import React, { useEffect, useState} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FadeIn from 'react-fade-in';
import Accordion from 'react-bootstrap/Accordion';

import anim from '../../images/anim.mp4';
import aiill from '../../images/ai_ill.png';
import present1 from '../../images/present/1.png';
import present2 from '../../images/present/2.png';
import present3 from '../../images/present/3.png';
import present4 from '../../images/present/4.png';
import present5 from '../../images/present/5.png';
import present6 from '../../images/present/6.png';
import present7 from '../../images/present/7.png';
import present8 from '../../images/present/8.png';
import present9 from '../../images/present/9.png';
import present10 from '../../images/present/10.png';
import present11 from '../../images/present/11.png';
import present12 from '../../images/present/12.png';
import present13 from '../../images/present/13.png';
import present14 from '../../images/present/14.png';
import present15 from '../../images/present/15.png';
import diversity1 from '../../images/diversity/1.png';
import diversity2 from '../../images/diversity/2.png';
import diversity3 from '../../images/diversity/3.png';
import diversity4 from '../../images/diversity/4.png';
import diversity5 from '../../images/diversity/5.png';
import diversity6 from '../../images/diversity/6.png';
import diversity7 from '../../images/diversity/7.png';
import diversity8 from '../../images/diversity/8.png';
import diversity9 from '../../images/diversity/9.png';
import diversity10 from '../../images/diversity/10.png';
import occultas1 from '../../images/occultas/1.jpg';
import occultas2 from '../../images/occultas/2.jpg';
import occultas3 from '../../images/occultas/3.jpg';
import occultas4 from '../../images/occultas/4.jpg';
import occultas5 from '../../images/occultas/5.jpg';
import occultas6 from '../../images/occultas/6.jpg';
import occultas7 from '../../images/occultas/7.jpg';
import occultas8 from '../../images/occultas/8.jpg';
import occultas9 from '../../images/occultas/9.jpg';
import occultas10 from '../../images/occultas/10.jpg';
import nativespecies1 from '../../images/nativespecies/1.jpg';
import nativespecies2 from '../../images/nativespecies/2.jpg';
import nativespecies3 from '../../images/nativespecies/3.jpg';
import nativespecies4 from '../../images/nativespecies/4.jpg';
import nativespecies5 from '../../images/nativespecies/5.jpg';
import nativespecies6 from '../../images/nativespecies/6.jpg';
import nativespecies7 from '../../images/nativespecies/7.jpg';
import nativespecies8 from '../../images/nativespecies/8.jpg';
import nativespecies9 from '../../images/nativespecies/9.jpg';
import nativespecies10 from '../../images/nativespecies/10.jpg';
import headphone1 from '../../images/headphone/JPEG/1.jpg';
import headphone2 from '../../images/headphone/JPEG/2.jpg';
import headphone3 from '../../images/headphone/JPEG/3.jpg';
import headphone4 from '../../images/headphone/JPEG/4.jpg';
import headphone5 from '../../images/headphone/JPEG/5.jpg';
import headphone6 from '../../images/headphone/JPEG/6.jpg';
import headphone7 from '../../images/headphone/JPEG/7.jpg';
import headphone8 from '../../images/headphone/JPEG/8.jpg';
import headphone9 from '../../images/headphone/JPEG/9.jpg';
import headphone10 from '../../images/headphone/JPEG/10.jpg';
import wol1 from '../../images/wol/1.png';
import wol2 from '../../images/wol/2.png';
import wol3 from '../../images/wol/3.png';
import wol4 from '../../images/wol/4.png';
import wol5 from '../../images/wol/5.png';
import wol6 from '../../images/wol/6.png';
import wol7 from '../../images/wol/7.png';
import wol8 from '../../images/wol/8.png';
import wol9 from '../../images/wol/9.png';
import wol10 from '../../images/wol/10.png';
import wol11 from '../../images/wol/11.png';
import wol12 from '../../images/wol/12.png';
import wol13 from '../../images/wol/13.png';
import wol14 from '../../images/wol/14.png';
import wol15 from '../../images/wol/15.png';
import scianime1 from '../../images/scianime/JPEG/1.jpg';
import scianime2 from '../../images/scianime/JPEG/2.jpg';
import scianime3 from '../../images/scianime/JPEG/3.jpg';
import scianime4 from '../../images/scianime/JPEG/4.jpg';
import scianime5 from '../../images/scianime/JPEG/5.jpg';
import scianime6 from '../../images/scianime/JPEG/6.jpg';
import scianime7 from '../../images/scianime/JPEG/7.jpg';
import scianime8 from '../../images/scianime/JPEG/8.jpg';
import urbananimals1 from '../../images/urbananimals/1.jpg';
import urbananimals2 from '../../images/urbananimals/2.jpg';
import urbananimals3 from '../../images/urbananimals/3.jpg';
import urbananimals4 from '../../images/urbananimals/4.jpg';
import urbananimals5 from '../../images/urbananimals/5.jpg';
import urbananimals6 from '../../images/urbananimals/6.jpg';
import urbananimals7 from '../../images/urbananimals/7.jpg';
import urbananimals8 from '../../images/urbananimals/8.jpg';
import urbananimals9 from '../../images/urbananimals/9.jpg';
import urbananimals10 from '../../images/urbananimals/10.jpg';


const Hero = () =>{

    useEffect(() => {
       
    }, [])
    const [key, setKey] = useState('art');
    return(
        <>
            <HS.TopContainer className='pb-4'>
                <div className='container'>
                    <div className='row'>
                    <h1>The AI illustrator</h1>
                    <div className='neon'><h2><b>Man<span>&nbsp;x&nbsp;</span>Machine</b></h2></div>
                    </div>
                </div>
            </HS.TopContainer>

            <HS.TopInfo className='container'>
                <div className='row mb-4'>
                    <section className='col-lg-6'>
                    <h1 className='mt-5'>Synthesis</h1>
                    <p>
                        Illustration is now freed from inside of pens and pencils -<br/> from the hands of man. <br/><br/>Now with the assistance of machine we can create illustrative styles and concepts with more freedom and imagination than ever before.
                        <br/><br/>
                        <strong>Embrace the synthesis.</strong>
                    </p>
                    </section>
                    <section className='col-lg-6'>
                        <video autoPlay loop muted playsInline className="img-fluid mt-5">
                            <source src={anim} type="video/mp4"/>
                        </video>
                    </section>
                </div>
            </HS.TopInfo>

            <HS.TabContainer className="col-lg-12">
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3 nav-pills"
                    >
                    <Tab eventKey="art" title="Art">
                    <FadeIn>
                        <section className="col-lg-12">
                            <Accordion className='container'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Tellurians
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Artificial intellience based image synthesis is, fundamentally, a brand new technology. A technology with inherent biases based upon it's training, and the dataset available to it. If the norms established by the dataset are lacking in human diversity, it's our job as the consumers of this technology to fight that bias. Tellurians are earth dwellers, nothing more nothing less. Enjoy the beauty of human biodiversity.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present10} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present11} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present12} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present13} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present14} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={present15} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                        <section className="col-lg-12">
                            <Accordion className='container'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Diverse Futures
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        We have been sold a vision of the future in the 70s and 80s - low life high tech. Now we live in that world. The influences of the origins of these concepts - shadowrun, bladerunner, clockwork orange, akira - much, much older - they all predicted the future, and we now live in that future. These characters are a reflection of our past, present and future.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={diversity10} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                        <section className="col-lg-12">
                            <Accordion className='container'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Occultas
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        It's the season of the witch. In a world full of mourning, it can be refreshing to enter a world where death is a celebration. Occultas is an homage to the central and south american traditions that celebrate death and life with reverance and beauty.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={occultas10} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                        <section className="col-lg-12">
                            <Accordion className='container'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        Species
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Living in the Pacific Northwest is an experience. Being surrounded by the enormous fauna of the region evokes feeling. As a non-native, but resident of the region, the AI illustrator has been inspired by the plant and animal life that is abundant in the region. This series in an homage to the people and plants that currently occupy the PNW.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={nativespecies10} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                    </FadeIn>
                    </Tab>
                    <Tab eventKey="concepts" title="Concept">
                    <FadeIn>
                        <section className="col-lg-12">
                            <h1 className='container'>Wol</h1>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol10} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol11} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol12} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol13} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol14} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={wol15} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                        <section className="col-lg-12">
                            <h1 className='container'>Headphonies</h1>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={headphone10} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                        <section className="col-lg-12">
                            <h1 className='container'>Scifi / Anime</h1>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={scianime8} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                        <section className="col-lg-12">
                            <h1 className='container'>Urban Animals</h1>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable={false}
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 3000, min: 1024 },
                                        items: 5,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 3,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                                >
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals1} // use normal <img> attributes as props
                                    />
                            </div>
                            <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals2} // use normal <img> attributes as props
                                    />
                            </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals3} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals4} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals5} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                    <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals6} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals7} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals8} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals9} // use normal <img> attributes as props
                                    />
                                </div>
                                <div>
                                <LazyLoadImage effect="blur"
                                    className="img-fluid"
                                    src={urbananimals10} // use normal <img> attributes as props
                                    />
                                </div>
                            </Carousel>
                        </section>
                    </FadeIn>
                    </Tab>
                </Tabs>
            </HS.TabContainer>

            <div className='container mx-auto'>
                <h1 >About the AI illustrator</h1>
                <div className='row'>
                    <div className='col-lg-6'>
                        <p className='text-left padded-p'>
                            The Ai Illustrator is an American artist and technologist curently residing in the Pacific Northwest. 
                            Traditionally trained in digital art and interactive media design, now drawing upon a lifetime of inspiration and experiences to create unique illustrative styles using artificial intelligence. 
                        </p>
                    </div>
                <div className='col-lg-6  text-center'>
                <LazyLoadImage effect="blur"
                    className="img-fluid"
                    width="200"
                    src={aiill} // use normal <img> attributes as props
                    />
                </div>
                </div>
            </div>
        </>
    )
}

export default Hero;