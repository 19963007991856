import * as FS from './Footer.styles';
function Footer() {
    return (
        <>
        <FS.FooterSection className="footer">
            <div className="container">
                <hr className="p-0" />
                <div className="row mx-0 footer-padding">
                    <div className="col-12 col-lg-4 order-lg-first my-lg-auto mb-4">
                        <h4>Social</h4>
                        <p>
                        <a href="https://twitter.com/aiillustrator">Twitter</a><br/>
                        <a href="https://instagram.com/theaiillustrator">Instagram</a><br/>
                        <a href="https://twitter.com/aiillustrator">Pixiv</a><br/>
                        </p>
                    </div>
                    <div className="col-12 col-lg-4 ">
                        <h4>Marketplaces</h4>
                        <p>
                        <a href="https://foundation.app/@aiillustrator">Foundation</a><br/>
                        <a href="https://opensea.io/0x9ef472A899a004A6942a456C9d3Ff58f81a6e28c?tab=created">Opensea</a><br/>
                        </p>
                    </div>
                    <div className="col-12 col-lg-4 ">
                        <h4>Contact</h4>
                        <p>
                        <a href="theaiillustrator@gmail.com">Email</a><br/>
                        </p>
                    </div>
                    <div className="col-12">
                        <p className='text-center'>
                            <small>All images, content, and code created by the AI illustrator 2022</small>
                        </p>
                    </div>
                </div>
            </div>
        </FS.FooterSection>
        </>

    ) 
}

export default Footer;
