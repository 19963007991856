import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import hero from '../../images/hero.jpg';
import info from '../../images/info.svg';


export const TopContainer = styled.section`
    margin:0;
    background-image:url(${hero});
    background-size:cover;
    background-position:top right;
    height:450px;
    position:relative;
    display:block;
    @media (max-width: 768px) {
        height:300px;
        background-position:top right;
    }
    @media (min-width: 1440px) {
        background-position:0 -150px;
    }

    h1 {
        background:#000;
        color:#f30a57;
        display:block;
        padding:10px 10px 2px 10px;
        font-size:90px;
        margin:0 auto;
        margin-top:10%;
        width: 645px;
        @media (max-width: 768px) {
            font-size: 40px;
            margin-top:25%;
            width: 368px;
            text-align:center;
            padding:10px 5px 5px 10px;
        }
    }
    .neon {
         user-select: none;
         text-align:center;
      }
      .neon h2 {
        background:transparent;
        padding-left:25px;
        @media (max-width: 768px) {
            padding-left:15px;
        }
      }
      .neon b{
        color: #fee;
        text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444;
      }
      .neon b span{
        animation: blink linear infinite 2s;
      }
      .neon b span:nth-of-type(2){
        animation: blink linear infinite 3s;
      }
      @keyframes blink {
        78% {
          color: inherit;
          text-shadow: inherit;
        }
        79%{
           color: #333;
        }
        80% {
          
          text-shadow: none;
        }
        81% {
          color: inherit;
          text-shadow: inherit;
        }
        82% {
          color: #333;
          text-shadow: none;
        }
        83% {
          color: inherit;
          text-shadow: inherit;
        }
        92% {
          color: #333;
          text-shadow: none;
        }
        92.5% {
          color: inherit;
          text-shadow: inherit;
        }
      }
`;

export const TopInfo = styled.div`
    video {
        width:100%;
    }
`;
export const TabContainer = styled.div`

padding-top:15px;
.nav-tabs {
    width:222px;
    margin:0 auto;
    border: 0 none;
    .nav-item button {
        background:transparent;
        border: 0 none transparent;
        font-family:'Edge', Serif;
        letter-spacing:2px;
        font-size:30px;
        line-height:1.5;
        color:#f30a57;
        border-radius:0;
        &.active {
            color:#fff;
            padding-bottom:5px;
            background:#f30a57;
        }
        &:hover {
            color:white;
        }

        @media (max-width: 768px) {
            width:auto;
        }
      }
}
    .accordion {
        padding:0;
        border:0 none;
        border-color:transparent;
        margin:25px auto;
        .accordion-header {
            padding-left:0;
        }
        .accordion-item {
            border:0 none;
            border-color:transparent;
            background-color:transparent;
        }
        .accordion-body {
            color:#fff;
            background-color:transparent;
        }
        .accordion-button {
            padding:10px 10px 6px 10px;
            background-color:transparent;
            font-size:2rem;
            letter-spacing:2px;
            color:white;
            &:focus, &:active {
                border-color:rgb(194, 8, 70);
                outline-color:rgb(194, 8, 70);
                box-shadow:none;
            }
            &:hover {
                color:rgb(194, 8, 70);
                &:after {
                    color:rgb(194, 8, 70);
                }
            }
            &:not(.collapsed) {
                color:rgb(194, 8, 70);
                border-color:transparent;
                box-shadow:none;
                &:after {
                    height:27px;
                    transform:none;
                }
            }
            &:after {
                background-image:url(${info});
                height:27px;
                -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
                filter: invert(100%);
            }
        }
    }
`;

export const HeroContent = styled.section`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFEFE;
    background:rgb(124, 128, 0);

    p {
        line-height:1.4;
    }
`;

export const HeroTitle = styled.h1`
    font-weight: 900;
    color:#333;
`;

export const HeroBtn = styled(Button)`
    text-decoration: none;
    outline: none;
    border: 1px solid #333;
    border-radius:6px;
    cursor:pointer;
    background-color:#bfc500;
    color:black;
    &:hover {
        text-decoration:underline;
        background:white;
        color:black;
    }
`;

